import { EntityService } from './EntityService';
import { IEntity, INAODefForm } from '../types';

export class ServerService extends EntityService<IEntity> {
    public readonly parentEntities = [];
    protected childrenEntities = [];

    constructor() {
        super(`/Servers`);
    }

    public readAllForLookup(includeClosed?: boolean): Promise<IEntity[]> {
        return this.readAll();
    }
}