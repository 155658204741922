import React, { useState } from "react";
import { AppGlobals } from "./AppGlobals";
import Logout from "./Logout";
import HomeTiles from "./HomeTiles";
import { ZedXMainMenu } from "./components/ZedXMainMenu";
import { MenuToggle } from "./components/MenuToggle";
import { ErrorBoundary } from "./components/ErrorBoundary";
import { IntegrationContext } from "./components/IntegrationContext";
import { DataContext } from "./components/DataContext";
import { OrbUserContext } from "./components/OrbUserContext";
import { useUserContext } from "./components/useUserContext";
import { useDataContext } from "./components/useDataContext";
import { useApi } from "./components/useApi";
import { IOrbProps } from "./App";
import { HashRouter, Route, Switch } from "react-router-dom";
import { ChangePassword } from "./components/account/ChangePassword";
import SystemAdministration from "./components/SystemAdministration";

interface ZedXMainAppProps extends IOrbProps {
    onLogout?: () => void;
    //authType: string;
}

export const ZedXMainApp = (props: ZedXMainAppProps): React.ReactElement => {

    const [isNavigationVisible, setNavigationVisible] = useState(false);

    const toggleNavigation = () => {
        setNavigationVisible(!isNavigationVisible);
    };

    const { dataServices, errorHandling } = props;
    const apiConnected = true; // useApi(dataServices/*, errorHandling*/);
    const uc = useUserContext(apiConnected, dataServices, errorHandling);
    const ld = useDataContext(apiConnected, dataServices, errorHandling);

    const routeProps = { ...props, apiConnected: apiConnected, ...uc, ...ld };

    return (
        <React.Fragment>
            <ErrorBoundary>
                <DataContext.Provider value={ld}>
                    <OrbUserContext.Provider value={uc}>

                        <div className="main">
                            <div className="blue-banner">
                                <MenuToggle onToggleNavigation={toggleNavigation} />
                                <div className="blue-banner-white-bold">Manage ZedX Apps</div>
                                <div className="right-aligned-text"><Logout onLogout={props.onLogout} /></div>
                            </div>
                            <div className="container">
                                <HashRouter>
                                    <div className={`navigation ${isNavigationVisible ? 'show' : ''}`}>
                                        <ZedXMainMenu
                                            onLogout={props.onLogout}
                                            onLinkClicked={toggleNavigation}
                                        />
                                    </div>
                                    <div className="content">
                                        <Switch>
                                            <Route exact path="/">
                                                <HomeTiles />
                                            </Route>
                                            <Route path="/sectors">
                                                <SystemAdministration  {...routeProps} entity="Sectors" />
                                            </Route>
                                            <Route path="/servers">
                                                <SystemAdministration  {...routeProps} entity="Servers" />
                                            </Route>
                                            <Route path="/apps">
                                                <SystemAdministration  {...routeProps} entity="Apps" />
                                            </Route>
                                            <Route path="/customers">
                                                <SystemAdministration  {...routeProps} entity="Customers" />
                                            </Route>
                                            <Route path="/customer-users/:customerRef" {...routeProps}>
                                                <SystemAdministration {...routeProps} entity="CustomerUsers" />
                                            </Route>
                                            <Route path="/account/change-password">
                                                <ChangePassword />
                                            </Route>
                                        </Switch>
                                    </div>
                                </HashRouter>
                            </div>
                        </div>

                    </OrbUserContext.Provider>
                </DataContext.Provider>
            </ErrorBoundary>
        </React.Fragment>
    );

};

