import React, { useContext, useMemo, useState } from 'react';
import { EntityStatus } from '../../refData/EntityStatus';
import styles from '../../styles/cr.module.scss';
import { IUser, User, ISpecificEntityFormProps, EntityValidations } from '../../types';
import { DataContext } from '../DataContext';
import { CrDropdown } from '../cr/CrDropdown';
import { CrTextField } from '../cr/CrTextField';
import { EntityForm } from '../EntityForm';
import { isValidEmailAddress, isValidGuid } from '../../services';
import { IntegrationContext } from '../IntegrationContext';
import { CrCheckbox } from '../cr/CrCheckbox';
import { AuthenticationTypes } from '../../refData/AuthenticationTypes';
import axios from 'axios';

export class UserValidations extends EntityValidations {
    public Username: string = null;
    public EmailAddress: string = null;
    public Password: string = null;
    public AuthenticationType = null;
}

interface UserFormProps extends ISpecificEntityFormProps {
    customerRef: string;
}

export const UserForm = (props: UserFormProps): React.ReactElement => {
    const { dataServices: { userService }, loadLookupData: { entityStatuses } } = useContext(DataContext);

    //const [passwordAction, setPasswordAction] = useState<number | string>(1);
    const [emailExistsCoreDb, setEmailExistsCoreDb] = useState<boolean>(null);

    useMemo(() => entityStatuses(), [entityStatuses]);

    const validateEntity = async (user: IUser): Promise<UserValidations> => {
        const errors = new UserValidations();
        //const [matchingUsernames, matchingEmails] = await Promise.all([userService.readUsersByUsername(user.Username), userService.readUsersByEmailAddress(user.EmailAddress)]);

        // if (user.Username == null || user.Username === '') {
        //     errors.Username = 'Username is required';
        //     errors.Valid = false;
        // } else if (!user.IsServiceAccount && !isValidEmailAddress(user.Username)) {
        //     errors.Username = 'Username must be in a valid user@domain.gov.uk format';
        //     errors.Valid = false;
        // } else if (user.IsServiceAccount && !isValidGuid(user.Username)) {
        //     errors.Username = 'Username must be in a valid GUID (00000000-0000-0000-0000-000000000000) format';
        //     errors.Valid = false;
        // } else if (matchingUsernames?.length > 0 && matchingUsernames?.some(u => u.ID !== user.ID)) {
        //     errors.Username = 'Username already exists';
        //     errors.Valid = false;
        // } else {
        //     errors.Username = null;
        // }

        if (user.Title == null || user.Title === '') {
            errors.Title = 'Display name is required';
            errors.Valid = false;
        }
        else {
            errors.Title = null;
        }

        if ((user.EmailAddress == null || user.EmailAddress === '')) {
            errors.EmailAddress = 'Email address is required';
            errors.Valid = false;
        } else if (!isValidEmailAddress(user.EmailAddress)) {
            errors.EmailAddress = 'Email address must be a valid email address';
            errors.Valid = false;
        }
        // else if (!user.IsServiceAccount && matchingEmails?.length > 0 && matchingEmails?.some(u => u.ID !== user.ID)) {
        //     errors.EmailAddress = 'Email address already exists';
        //     errors.Valid = false;
        // } else {
        //     errors.EmailAddress = null;
        // }
        if (user.AuthenticationTypeID === null) {
            errors.AuthenticationType = 'Authentication Type is required';
            errors.Valid = false;
        }
        else {
            errors.AuthenticationType = null;
        }
        if (emailExistsCoreDb !== null && user.AuthenticationTypeID === 1 && (user.PasswordActionID === 1 || user.PasswordActionID === 3)) {
            if (user.Password == null || user.Password === '') {
                errors.Password = 'Password is required';
                errors.Valid = false;
            }
            else
                errors.Password = null;

        }
        else {
            errors.Password = null;
        }

        return errors;
    };

    const passwordActions = [
        { key: 1, text: 'Set Password' },
        { key: 2, text: 'None' },
        { key: 3, text: 'Reset Password' },
    ];

    const getPasswordActions = () => {
        if (emailExistsCoreDb === false)
            return passwordActions.filter(x => x.key === 1);
        else if (emailExistsCoreDb === true) {
            return passwordActions.filter(x => x.key > 1);
        }
        return [];
    }

    const checkEmailOnCoreDb = async (email: string): Promise<boolean> => {

        console.log('call api to check if email exist in core db');
        const emailExist: boolean = await userService.emailExistOnCoreUsers(email);
        // const zedxApiURL = process.env.REACT_APP_ZedX_Non_Odata_API!;
        // const response = await axios.post(`${zedxApiURL}/Accounts/EmailExists`, {
        //     EmailAddress: email
        // });

        // console.log('EmailExists response:', response.data);
        // const emailExist: boolean = response.data;
        setEmailExistsCoreDb(emailExist);
        return emailExist;
    }

    return (
        <EntityForm<IUser, UserValidations>
            {...props}
            entityName="User"
            renderFormFields={({ changeTextField, changeNumberField, changeCheckbox, changeDropdown, changeStatusDropdown, clearField }, formState) => {
                const { FormData: user, ValidationErrors: errors } = formState;

                return (
                    <div>
                        <CrTextField
                            label="Display name"
                            className={styles.formField}
                            required={true}
                            maxLength={255}
                            value={user.Title}
                            onChange={(ev, newValue) => changeTextField(newValue, 'Title')}
                            errorMessage={errors.Title}
                        />
                        <CrTextField
                            label="Email address"
                            className={styles.formField}
                            required={true}
                            maxLength={255}
                            placeholder="E.g. john.smith@yourdomain.com"
                            value={user.EmailAddress || ''}
                            onChange={(ev, newValue) => changeTextField(newValue, 'EmailAddress')}
                            errorMessage={errors.EmailAddress}
                        />
                        <CrDropdown
                            label="Authentication Type"
                            className={styles.formField}
                            required={true}
                            errorMessage={errors.AuthenticationType}
                            options={[{ key: AuthenticationTypes.ZedX, text: 'ZedX' }, { key: AuthenticationTypes.Azure, text: 'Azure' }]}
                            selectedKey={user.AuthenticationTypeID}
                            onChange={(_, o) => {
                                changeDropdown(o, 'AuthenticationTypeID', null, async (u) => {
                                    if (u.AuthenticationTypeID === AuthenticationTypes.ZedX) {
                                        if (!isValidEmailAddress(u.EmailAddress)) {
                                            console.log('invalid email address');
                                        }
                                        else {
                                            const emailExist = await checkEmailOnCoreDb(u.EmailAddress);
                                            if (emailExist == true) {
                                                changeNumberField(2, 'PasswordActionID');//default None
                                            }

                                        }

                                    }

                                });

                            }}
                        />
                        {emailExistsCoreDb !== null && user.AuthenticationTypeID === 1 &&
                            <CrDropdown
                                label="Password Actions"
                                className={styles.formField}
                                options={getPasswordActions()}
                                selectedKey={user.PasswordActionID}
                                onChange={(_, o) => {
                                    console.log(o.key);
                                    changeDropdown(o, 'PasswordActionID')
                                    //setPasswordAction(o.key);
                                }}
                            />
                        }
                        {emailExistsCoreDb !== null && user.AuthenticationTypeID === 1 && (user.PasswordActionID === 1 || user.PasswordActionID === 3) &&
                            <CrTextField
                                label="Password"
                                type='password'
                                canRevealPassword
                                className={styles.formField}
                                required={true}
                                maxLength={255}
                                autoComplete="new-password"
                                value={user.Password}
                                onChange={(ev, newValue) => changeTextField(newValue, 'Password')}
                                errorMessage={errors.Password}
                            />
                        }
                        {user.ID !== 0 &&
                            <CrDropdown
                                label="Account status"
                                className={styles.formField}
                                required={true}
                                options={[{ key: EntityStatus.Open, text: 'Enabled' }, { key: EntityStatus.Closed, text: 'Disabled' }]}
                                selectedKey={user.EntityStatusID}
                                onChange={(_, o) => changeStatusDropdown(o, 'EntityStatusID')}
                            />
                        }
                    </div>
                );
            }}
            loadEntity={(id) => {
                return userService.readCustomerUser(props.customerRef, id);
            }}
            onAfterLoad={async (entity) => {
                console.log('on after load', entity);
                await checkEmailOnCoreDb(entity.EmailAddress);
            }}            
            loadNewEntity={() => new User(props.customerRef)}
            loadEntityValidations={() => new UserValidations()}
            onValidateEntity={validateEntity}
            onCreate={u => userService.create(u)}
            onUpdate={u => userService.updateCustomerUser(u.ID, props.customerRef, u)}
            closeEntityConfirm={{
                header: `Are you sure you want to disable this user account?`,
                text: `All the permissions for this user will be deleted. `
                    + `If you need to re-enable this user account, you will also need to set up its user permissions.`
            }}
        />
    );
};
