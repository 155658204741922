import React, { useContext, useEffect } from 'react';
import { DataContext } from './components/DataContext';


const HomeTiles: React.FC = () => {

    const imagesRoot: string = process.env.REACT_APP_ZedXImagesRoot!;
    const organiseServersIcon: string = `${imagesRoot}servers.png`;
    const defineSectorsIcon: string = `${imagesRoot}sectors.png`;
    const appsIcon: string = `${imagesRoot}apps.png`;
    const manageCustomersIcon: string = `${imagesRoot}customers.png`;

    const { dataServices } = useContext(DataContext);

    // useEffect(() => {

    //     const fetchWorkflow = async () => {
    //         if (true) {
                
    //             try {
    //                 const temp = await dataServices.sectorService.readAll();
    //                 console.log(temp[0].Title);
    //                 //console.log(temp);

                    
    //             } catch (error) {
    //                 console.error('Error fetching data:', error);
    //             }
    //             finally {
                    
    //             }
    //         }
    //     };

    //     fetchWorkflow();
    // }, []);

    return (
        <div className="apps-home">
            <div className="rectangle">
                <a href="#/servers">
                    <img src={organiseServersIcon} alt="Organise Servers" />
                    <div className="rectangle-title">Organise Servers</div>
                </a>
            </div>
            <div className="rectangle">
                <a href="#/sectors">
                    <img src={defineSectorsIcon} alt="Define Sectors" />
                    <div className="rectangle-title">Define Sectors</div>
                </a>
            </div>
            <div className="rectangle">
                <a href="#/apps">
                    <img src={appsIcon} alt="Apps" />
                    <div className="rectangle-title">Apps</div>
                </a>
            </div>
            <div className="rectangle">
                <a href="#/customers">
                    <img src={manageCustomersIcon} alt="Manage Customers" />
                    <div className="rectangle-title">Manage Customers</div>
                </a>
            </div>
        </div>
    );
};

export default HomeTiles;
