import { IEntity } from "./Entity";

export interface ICustomer extends IEntity {
    CustomerRef?: string;
    SectorID?: number;
    ServerID?: number;
    //FirstUserName?: string;
    //FirstUserEmail?: string;
    //FirstUserPassword?: string;
}

export class Customer implements ICustomer {
    public ID: number = 0;
    public Title: string = null;
    public CustomerRef?: string = null;
    public SectorID?: number = null;
    public ServerID?: number = null;
    //public FirstUserName?: string = null;
    //public FirstUserEmail?: string = null;
    //public FirstUserPassword?: string = null;
}