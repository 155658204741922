import { EntityService } from './EntityService';
import { IEntity, INAODefForm } from '../types';

export class AvailableAppService extends EntityService<IEntity> {
    public readonly parentEntities = [];
    protected childrenEntities = [];

    constructor() {
        super(`/AvailableApps`);
    }
}