import React, { useEffect, useState } from 'react';
import ReactDOM from 'react-dom';
import { mergeStyles } from '@fluentui/react';
import reportWebVitals from './reportWebVitals';
import { MsalProvider } from "@azure/msal-react";
import { msalConfig } from "./authConfig";
import { PublicClientApplication } from '@azure/msal-browser';


import {
  AttributeService,
  OrgConfigService,
  AttributeTypeService,
  BenefitService,
  BenefitTypeService,
  BenefitUpdateService,
  CommitmentService,
  CommitmentUpdateService,
  ContributorService,
  CorporateRiskMitigationActionService,
  CorporateRiskMitigationActionUpdateService,
  CorporateRiskRiskMitigationActionService,
  CorporateRiskService,
  CorporateRiskUpdateService,
  DepartmentalObjectiveService,
  DependencyService,
  DependencyUpdateService,
  DirectorateService,
  DirectorateUpdateService,
  EntityStatusService,
  FinancialRiskMitigationActionService,
  FinancialRiskMitigationActionUpdateService,
  FinancialRiskService,
  FinancialRiskUpdateService,
  FinancialRiskUserGroupService,
  OrgLevel1Service,
  HealthCheckService,
  KeyWorkAreaService,
  KeyWorkAreaUpdateService,
  ListService,
  MeasurementUnitService,
  MetricService,
  MetricUpdateService,
  MilestoneService,
  MilestoneTypeService,
  MilestoneUpdateService,
  PartnerOrganisationRiskMitigationActionService,
  PartnerOrganisationRiskMitigationActionUpdateService,
  PartnerOrganisationRiskRiskTypeService,
  PartnerOrganisationRiskService,
  PartnerOrganisationRiskUpdateService,
  PartnerOrganisationService,
  PartnerOrganisationUpdateService,
  ProjectBusinessCaseTypeService,
  ProjectPhaseService,
  ProjectStageService,
  ProjectService,
  ProjectUpdateService,
  ReportBuilderService,
  ReportDueDatesService,
  ReportingEntityTypeService,
  ReportingFrequencyService,
  RiskAppetiteService,
  RiskDiscussionForumService,
  RiskImpactLevelService,
  RiskPermissionsService,
  RiskProbabilityService,
  //RiskRegisterService,
  RiskRiskTypeService,
  RiskTypeService,
  RoleService,
  SignOffService,
  //SiteService,
  ThresholdAppetiteService,
  ThresholdService,
  //TokenRefreshService,
  UserDirectorateService,
  UserGroupService,
  UserPartnerOrganisationService,
  UserProjectService,
  UserRoleService,
  UserService,
  WorkStreamService,
  WorkStreamUpdateService,
  OrgLevel3Service,
  PortfolioService,
  PortfolioUpdateService,
  ProgrammeService,
  ProgrammeStageService,
  ProgrammeUpdateService,
  ProgrammePhaseService,
  App03WorkflowService,
  App03WorkflowStageService,
  App03WorkflowUserService,
  App03TaskService,
  App03TaskUserService,
  GIAADefFormService,
  NAODefFormService,
  CLDefFormService,
  ZFileService,
  GoDefFormService,
  IAPDefFormService,
  CADefFormService,
  SectorService,
  ServerService,
  CustomerService,
  AvailableAppService
} from './services';
import { IDataServices } from './types';
import { withErrorHandling } from './components/withErrorHandling';
import App from './App';
import axios from 'axios';
import { AppGlobals } from './AppGlobals';


// Inject some global styles
mergeStyles({
  ':global(body,html,#root)': {
    margin: 0,
    padding: 0,
    height: '100vh',
  },
});

const dataServices = {
  healthCheckService: new HealthCheckService(),
  attributeService: new AttributeService(),
  attributeTypeService: new AttributeTypeService(),
  orgConfigService: new OrgConfigService(),
  benefitService: new BenefitService(),
  benefitTypeService: new BenefitTypeService(),
  benefitUpdateService: new BenefitUpdateService(),
  commitmentService: new CommitmentService(),
  commitmentUpdateService: new CommitmentUpdateService(),
  contributorService: new ContributorService(),
  corporateRiskMitigationActionService: new CorporateRiskMitigationActionService(),
  corporateRiskMitigationActionUpdateService: new CorporateRiskMitigationActionUpdateService(),
  corporateRiskRiskMitigationActionService: new CorporateRiskRiskMitigationActionService(),
  corporateRiskService: new CorporateRiskService(),
  corporateRiskUpdateService: new CorporateRiskUpdateService(),
  departmentalObjectivesService: new DepartmentalObjectiveService(),
  dependencyService: new DependencyService(),
  dependencyUpdateService: new DependencyUpdateService(),
  directorateService: new DirectorateService(),
  directorateUpdateService: new DirectorateUpdateService(),
  //economicRingfenceService: new ListService(context, this.properties.economicRingfencesUrl),
  entityStatusService: new EntityStatusService(),
  financialRiskMitigationActionService: new FinancialRiskMitigationActionService(),
  financialRiskMitigationActionUpdateService: new FinancialRiskMitigationActionUpdateService(),
  financialRiskService: new FinancialRiskService(),
  financialRiskUpdateService: new FinancialRiskUpdateService(),
  financialRiskUserGroupService: new FinancialRiskUserGroupService(),
  //fundingClassificationService: new ListService(context, this.properties.fundingClassificationsUrl),
  orgLevel1Service: new OrgLevel1Service(),
  keyWorkAreaService: new KeyWorkAreaService(),
  keyWorkAreaUpdateService: new KeyWorkAreaUpdateService(),
  measurementUnitService: new MeasurementUnitService(),
  metricService: new MetricService(),
  metricUpdateService: new MetricUpdateService(),
  milestoneService: new MilestoneService(),
  milestoneTypeService: new MilestoneTypeService(),
  milestoneUpdateService: new MilestoneUpdateService(),
  partnerOrganisationRiskMitigationActionService: new PartnerOrganisationRiskMitigationActionService(),
  partnerOrganisationRiskMitigationActionUpdateService: new PartnerOrganisationRiskMitigationActionUpdateService(),
  partnerOrganisationRiskRiskTypeService: new PartnerOrganisationRiskRiskTypeService(),
  partnerOrganisationRiskService: new PartnerOrganisationRiskService(),
  partnerOrganisationRiskUpdateService: new PartnerOrganisationRiskUpdateService(),
  partnerOrganisationService: new PartnerOrganisationService(),
  partnerOrganisationUpdateService: new PartnerOrganisationUpdateService(),
  //policyRingfenceService: new ListService(context, this.properties.policyRingfencesUrl),
  projectBusinessCaseTypeService: new ProjectBusinessCaseTypeService(),
  projectPhaseService: new ProjectPhaseService(),
  projectStageService: new ProjectStageService(),
  projectService: new ProjectService(),
  projectUpdateService: new ProjectUpdateService(),
  reportBuilderService: new ReportBuilderService(),
  reportDueDatesService: new ReportDueDatesService(),
  //reportingEntityService: new ReportingEntityService(),
  reportingEntityTypeService: new ReportingEntityTypeService(),
  //reportingEntityUpdateService: new ReportingEntityUpdateService(),
  reportingFrequencyService: new ReportingFrequencyService(),
  riskAppetiteService: new RiskAppetiteService(),
  riskDiscussionForumService: new RiskDiscussionForumService(),
  riskImpactLevelService: new RiskImpactLevelService(),
  riskPermissionsService: new RiskPermissionsService(),
  riskProbabilityService: new RiskProbabilityService(),
  riskRiskTypeService: new RiskRiskTypeService(),
  riskTypeService: new RiskTypeService(),
  roleService: new RoleService(),
  signOffService: new SignOffService(),
  //siteService: new SiteService(context),
  thresholdService: new ThresholdService(),
  thresholdAppetiteService: new ThresholdAppetiteService(),
  //tokenRefreshService: new TokenRefreshService(context, this.properties.appIdUri),
  //budgetingEntitiesService: new ListService(context, this.properties.budgetingEntitiesUrl),
  userDirectorateService: new UserDirectorateService(),
  userGroupService: new UserGroupService(),
  userPartnerOrganisationService: new UserPartnerOrganisationService(),
  userProjectService: new UserProjectService(),
  userRoleService: new UserRoleService(),
  userService: new UserService(),
  workStreamService: new WorkStreamService(),
  workStreamUpdateService: new WorkStreamUpdateService(),
  orgLevel3Service: new OrgLevel3Service(),
  portfolioService: new PortfolioService(),
  portfolioUpdateService: new PortfolioUpdateService(),
  programmeService: new ProgrammeService(),
  programmeStageService: new ProgrammeStageService(),
  programmeUpdateService: new ProgrammeUpdateService(),
  programmePhaseService: new ProgrammePhaseService(),
  app03WorkflowService: new App03WorkflowService(),
  app03WorkflowUserService: new App03WorkflowUserService(),
  app03WorkflowStageService: new App03WorkflowStageService(),
  app03TaskService: new App03TaskService(),
  app03TaskUserService: new App03TaskUserService(),
  gIAADefFormService: new GIAADefFormService(),
  naoDefFormService: new NAODefFormService(),
  clDefFormService: new CLDefFormService(),
  goDefFormService: new GoDefFormService(),
  iapDefFormService: new IAPDefFormService(),
  caDefFormService: new CADefFormService(),
  zFileService: new ZFileService(),
  sectorService: new SectorService(),
  serverService: new ServerService(),
  customerService: new CustomerService(),
  availableAppService: new AvailableAppService(),
}


const onError = (errorUserMessage: string, errorDetail?: string): void => {
  //this.setState({ Error: errorUserMessage });
  //if (errorDetail) console.log(`${errorUserMessage}: ${errorDetail}`);
}
const clearErrors = () => {
  //this.setState({ Error: null });
}
const onFirstAPIRequestError = (errorType: string/*, tokenRefreshService: ITokenRefreshService*/) => {
  //this.setState({ FirstAPICallError: errorType/*, TokenRefreshService: tokenRefreshService*/ });
}




const renderApp = (
  <React.Fragment>
    <App
      dataServices={dataServices}
      errorHandling={{
        onError: onError,
        clearErrors: clearErrors,
        onFirstAPIRequestError: onFirstAPIRequestError
      }}
    />
  </React.Fragment>
);

const rootElement = document.getElementById('root');
ReactDOM.render(renderApp, rootElement);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
