import React, { useState } from 'react';
import { initializeIcons } from '@fluentui/react';
import { HashRouter, Switch, Route } from 'react-router-dom';
import './App.css';
import { IWithErrorHandlingProps } from './components/withErrorHandling';
import { IDataServices } from './types';
import { AppGlobals } from './AppGlobals';
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from "@azure/msal-react";
import { MsSignIn } from './MsSignIn';
import { ZedXMainApp } from './ZedXMainApp';
import { CustomSignIn } from './CustomSignIn';
import { ManageProjectsAppWithErrorHandling } from './ManageProjectsApp';
import { TrackRisksAppWithErrorHandling } from './TrackRisksApp';
import { AccountAdminAppWithErrorHandling } from './AccountAdminApp';
import { ProgresUpdatesAppWithErrorHandling } from './ProgresUpdatesApp';
import { ButtonCommandBarExample } from './test/ButtonCommandBarExample';
import { CommandBarBasicExample } from './test/CommandBarBasicExample';
import MobileDetector from './test/MobileDetector';
import MobileDetector2 from './test/MobileDetector2';
import MobileDetectorUsingHook from './test/MobileDetectorUsingHook';
import { CorporateWorkflowsAppWithErrorHandling } from './CorporateWorkflowsApp';
import { GIAAActionsAppWithErrorHandling } from './GIAAActionsApp';
import BlobFileDownloadTest from './test/BlobFileDownloadTest';
import FileUploadTest from './test/FileUploadTest';
import { NAOTrackerAppWithErrorHandling } from './NAOTrackerApp';
import { CLAppWithErrorHandling } from './CLApp';
import { GoAppWithErrorHandling } from './GoApp';
import { IAPAppWithErrorHandling } from './IAPApp';
import { CAAppWithErrorHandling } from './CAApp';

initializeIcons();
Intl.DateTimeFormat().resolvedOptions().timeZone = 'UTC'; // Set timezone


export interface IOrbProps extends IWithErrorHandlingProps {
  dataServices: IDataServices;
  //authType: string;
  onLogout?: () => void;
}

const App: React.FunctionComponent<IOrbProps> = (props: IOrbProps) => {

  const [userLoggedInAndSelected, setUserLoggedInAndSelected] = useState<boolean>(false);
  const { instance } = useMsal();

  const handleAccountSelection = () => {
    console.log('in handleAccountSelection', AppGlobals.Username);
    //console.log('customer id', AppGlobals.CustomerID);
    setUserLoggedInAndSelected(true);
  };

  const handleLogout = () => {
    console.log('in handleLogout');
    if (AppGlobals.AuthType === "CustomJwt") {
      AppGlobals.UserID = null;
      AppGlobals.Username = null;
      AppGlobals.UserTitle = null;
      AppGlobals.CustomJwtToken = null;
      AppGlobals.CustomerID = null;
      AppGlobals.CustomerTitle = null;
      window.location.href = AppGlobals.ZedxNetSiteUrl;
    }
    else {
      //AzureAd Logout
      sessionStorage.clear();
      instance.logoutRedirect({
        postLogoutRedirectUri: "/"
      });

    }
    setUserLoggedInAndSelected(false);
  };

  //const { authType } = props;
  //console.log('authType', authType);

  const renderApps = (
    <HashRouter>
      <Switch>
        <Route
          exact
          path={["/", "/sectors/:any*", "/servers/:any*", "/customers/:any*", "/customer-users/:any*", "/apps/:any*"]} // Specify multiple paths
          render={({ match }) => {
            return (
              <ZedXMainApp
                onLogout={handleLogout}
                {...props}
              />
            );
          }}
        />

        {/* <Route
          path="/performance-reporting/:any*"
          render={({ match }) => {
            return <ManageProjectsAppWithErrorHandling onLogout={handleLogout} {...props} />;
          }}
        /> */}

      </Switch>
    </HashRouter>
  );

  // const renderAppTest = (
  //   <FileUploadTest
  //     authType={authType}
  //     onLogout={handleLogout}
  //     {...props} />
  // );


  return (
    <React.Fragment>
      {userLoggedInAndSelected ? (
        renderApps
      ) : (
        <CustomSignIn
          onAccountSelection={handleAccountSelection}
        />
      )}
    </React.Fragment>
  );

};
export default App;