import React, { useContext, useMemo } from 'react';
import { EntityValidations, ISpecificEntityFormProps, ICustomer, Customer } from '../../types';
import { DateService, LookupService } from '../../services';
import styles from '../../styles/cr.module.scss';
import { CrTextField } from '../cr/CrTextField';
import { CrDropdown } from '../cr/CrDropdown';
import { EntityForm } from '../EntityForm';
import { DataContext } from '../DataContext';
import { OrbUserContext } from '../OrbUserContext';


class CustomerValidations extends EntityValidations {
    public CustomerRef: string = null;
    public SectorID: string = null;
    public ServerID: string = null;
    //public FirstUserEmail: string = null;
    //public FirstUserName: string = null;
    //public FirstUserPassword: string = null;
}

export const CustomerForm = (props: ISpecificEntityFormProps): React.ReactElement => {
    const { userPermissions, orbConfig } = useContext(OrbUserContext);
    const { dataServices, lookupData, loadLookupData: { sectors, servers, } } = useContext(DataContext);

    useMemo(() => sectors(), [sectors]);
    useMemo(() => servers(), [servers]);

    const validateEntity = (customer: ICustomer): Promise<CustomerValidations> => {
        const errors = new CustomerValidations();

        if (customer.Title === null || customer.Title === '') {
            errors.Title = `Customer name is required`;
            errors.Valid = false;
        }
        else {
            errors.Title = null;
        }
        if (customer.CustomerRef === null || customer.CustomerRef === '') {
            errors.CustomerRef = `Customer ref is required`;
            errors.Valid = false;
        }
        else {
            errors.CustomerRef = null;
        }
        if (customer.SectorID === null) {
            errors.SectorID = `Sector is required`;
            errors.Valid = false;
        }
        else {
            errors.SectorID = null;
        }
        if (customer.ServerID === null) {
            errors.ServerID = `Server is required`;
            errors.Valid = false;
        }
        else {
            errors.ServerID = null;
        }

        // if (customer.FirstUserName === null || customer.FirstUserName === '') {
        //     errors.FirstUserName = `First User Name is required`;
        //     errors.Valid = false;
        // }
        // else {
        //     errors.FirstUserName = null;
        // }
        // if (customer.FirstUserEmail === null || customer.FirstUserEmail === '') {
        //     errors.FirstUserEmail = `First User Email Address is required`;
        //     errors.Valid = false;
        // }
        // else {
        //     errors.FirstUserEmail = null;
        // }
        // if (customer.FirstUserPassword == null || customer.FirstUserPassword === '') {
        //     errors.FirstUserPassword = 'Password is required';
        //     errors.Valid = false;
        // }
        // else {
        //     errors.FirstUserPassword = null;
        // }

        return Promise.resolve(errors);
    };


    return (
        <EntityForm<ICustomer, CustomerValidations>
            {...props}
            entityName='Customer'
            renderFormFields={(changeHandlers, formState) => {
                const { FormData: customer, ValidationErrors: errors } = formState;
                return (
                    <div>
                        <CrTextField
                            label="Name"
                            maxLength={100}
                            className={styles.formField}
                            required={true}
                            value={customer.Title}
                            onChange={(_, value) => changeHandlers.changeTextField(value, 'Title')}
                            errorMessage={errors.Title}
                        />
                        <CrTextField
                            label="Customer Ref"
                            maxLength={100}
                            className={styles.formField}
                            required={true}
                            value={customer.CustomerRef}
                            onChange={(_, value) => changeHandlers.changeTextField(value, 'CustomerRef')}
                            errorMessage={errors.CustomerRef}
                        />
                        <CrDropdown
                            label='Sector'
                            className={styles.formField}
                            options={LookupService.entitiesToSelectableOptions(lookupData.Sectors)}
                            selectedKey={customer.SectorID}
                            onChange={(_, option, index) => changeHandlers.changeDropdown(option, 'SectorID', index)}
                            errorMessage={errors.SectorID}
                        />
                        <CrDropdown
                            label='Server'
                            className={styles.formField}
                            options={LookupService.entitiesToSelectableOptions(lookupData.Servers)}
                            selectedKey={customer.ServerID}
                            onChange={(_, option, index) => changeHandlers.changeDropdown(option, 'ServerID', index)}
                            disabled={customer.ID > 0}
                            errorMessage={errors.ServerID}
                        />
                        {/* <CrTextField
                            label="First User Name"
                            maxLength={100}
                            className={styles.formField}
                            required={true}
                            value={customer.FirstUserName}
                            onChange={(_, value) => changeHandlers.changeTextField(value, 'FirstUserName')}
                            errorMessage={errors.FirstUserName}
                        />
                        <CrTextField
                            label="First User Email address"
                            className={styles.formField}
                            required={true}
                            maxLength={255}
                            placeholder="E.g. john.smith@yourdomain.com"
                            value={customer.FirstUserEmail}
                            onChange={(_, value) => changeHandlers.changeTextField(value, 'FirstUserEmail')}
                            errorMessage={errors.FirstUserEmail}
                        />
                        <CrTextField
                            label="Password"
                            type='password'
                            canRevealPassword
                            className={styles.formField}
                            required={true}
                            maxLength={255}
                            autoComplete="new-password"
                            value={customer.FirstUserPassword}
                            onChange={(_, value) => changeHandlers.changeTextField(value, 'FirstUserPassword')}
                            errorMessage={errors.FirstUserPassword}
                        /> */}
                    </div>
                );
            }}
            loadEntity={id => dataServices.customerService.read(id, true, true)}
            loadNewEntity={() => new Customer()}
            loadEntityValidations={() => new CustomerValidations()}
            onValidateEntity={validateEntity}
            onCreate={g => dataServices.customerService.create(g)}
            onUpdate={g => dataServices.customerService.update(g.ID, g)}
            parentEntities={dataServices.customerService.parentEntities}

        />
    );
};
