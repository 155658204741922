import { loginRequest } from "./authConfig";
import { createTheme } from '@fluentui/react/lib/Styling';

export class AppGlobals {
    public static EntraClientId: string | null = null;
    public static CustomerID: number | null = null;
    public static Username: string | null = null;
    public static UserID: number | null = null;
    public static CustomerTitle: string | null = null;
    public static CustomerSectorTitle: string | null = null;
    public static CustomerRef: string | null = null;
    public static UserTitle: string | null = null;
    public static AuthType: string | null = null;
    public static CustomJwtToken: string | null = null;

    public static readonly ZedxNetSiteUrl: string = 'https://www.zedx.net';


    public static acquireAccessToken = async () => {
        return this.CustomJwtToken;
    };
}

export const darkMenuTheme = createTheme({
    palette: {
        themePrimary: '#ffffff',
        themeLighterAlt: '#f3f3f3',
        themeLighter: '#dcdcdc',
        themeLight: '#c8c8c8',
        themeTertiary: '#a6a6a6',
        themeSecondary: '#868686',
        themeDarkAlt: '#6e6e6e',
        themeDark: '#555555',
        themeDarker: '#3f3f3f',
        neutralLighterAlt: '#2b2b2b',
        neutralLighter: '#323232',
        neutralLight: '#3f3f3f',
        neutralQuaternaryAlt: '#484848',
        neutralQuaternary: '#4f4f4f',
        neutralTertiaryAlt: '#6d6d6d',
        neutralTertiary: '#c8c8c8',
        neutralSecondary: '#d0d0d0',
        neutralPrimaryAlt: '#dadada',
        neutralPrimary: '#ffffff',
        neutralDark: '#f4f4f4',
        black: '#ffffff',
        white: '#494949',
    },
});

