import { EntityWithStatusService } from './EntityWithStatusService';
import { ContextService } from './ContextService';
import { IUser, IEntityChildren } from '../types';
import { EntityStatus } from '../refData/EntityStatus';
import axios from 'axios';
import { AppGlobals } from '../AppGlobals';

export class UserService extends EntityWithStatusService<IUser> {
    //private _username: string;

    constructor() {
        super(`/Users`);
        //this._username = spfxContext.pageContext.user.loginName;
        //this._username = 'tas@swiftpro.com'; //this is also hard coded in ContextService
        //this._username = AppGlobals.Username;
    }

    get Username(): string {
        //return ContextService.alterUsername(this._username);
        //return ContextService.alterUsername(AppGlobals.Username);
        return AppGlobals.Username;
    }

    public async emailExistOnCoreUsers(emailAddress: string): Promise<boolean> {
        const requestBody = { Email: emailAddress };
        const emailExist = await super.readAnyValueWithPost('EmailExists', requestBody);
        return Boolean(emailExist);
    }

    public async readCustomerUser(customerRef: string, id: number): Promise<IUser> {
        const requestBody = { CustomerRef: customerRef, ID: id };
        const user: IUser = await super.readAnyValueWithPost('ReadCustomerUser', requestBody);
        return user;
    }

    public async updateCustomerUser(id: number, customerRef: string, user: IUser): Promise<void> {
        user['CustomerRef'] = customerRef;
        return super.update(id, user);
    }







    //delete below (existing code from zex)


    public async readMyPermissions(): Promise<IUser> {

        console.log('in UserService - readMyPermissions', this.Username);

        const qry: string = `${this.entityUrl}/GetUserPermissions(Username='${encodeURIComponent(this.Username)}')?$select=ID,Title,Username,EntityStatusID`
            + `&$expand=UserRoles($select=ID,UserID,RoleID)`
            + `,UserGroups($select=ID,UserID,GroupID,IsRiskAdmin;$expand=OrgLevel1($select=ID,Title))`
            + `,UserDirectorates($select=ID,UserID,DirectorateID,IsAdmin,IsRiskAdmin;$expand=Directorate($select=ID,Title))`
            + `,UserProjects($select=ID,UserID,ProjectID,IsAdmin,IsRiskAdmin;$expand=Project($select=ID,Title))`
            + `,UserPartnerOrganisations($select=ID,UserID,PartnerOrganisationID,IsAdmin,HideHeadlines,HideMilestones,HideCustomSections;$expand=PartnerOrganisation($select=ID,Title))`
            + `,DirectorateDirectorUsers($select=ID,Title)`
            + `,DirectorateReportApproverUsers($select=ID,Title)`
            + `,ProjectSeniorResponsibleOwnerUsers($select=ID,Title)`
            + `,ProjectReportApproverUsers($select=ID,Title)`
            + `,CorporateRiskRiskOwnerUsers($select=ID,Title)`
            + `,CorporateRiskReportApproverUsers($select=ID,Title)`
            + `,FinancialRiskRiskOwnerUsers($select=ID,Title)`
            + `,FinancialRiskReportApproverUsers($select=ID,Title)`
            + `,ContributorContributorUsers($expand=CorporateRisk($select=ID,Title),FinancialRisk($select=ID,Title))`
            + `,PartnerOrganisationLeadPolicySponsorUsers($select=ID,Title)`
            + `,PartnerOrganisationReportAuthorUsers($select=ID,Title)`
            + `,FinancialRiskUserGroups($select=ID,UserID,GroupID;$expand=OrgLevel1($select=ID,Title))`;

        console.log(qry);


        const user: IUser = (await this.axiosGet(`${this.entityUrl}/GetUserPermissions(Username='${encodeURIComponent(this.Username)}')?$select=ID,Title,Username,EntityStatusID`
            + `&$expand=UserRoles($select=ID,UserID,RoleID)`
            + `,UserGroups($select=ID,UserID,GroupID,IsRiskAdmin;$expand=OrgLevel1($select=ID,Title))`
            + `,UserDirectorates($select=ID,UserID,DirectorateID,IsAdmin,IsRiskAdmin;$expand=Directorate($select=ID,Title))`
            + `,UserProjects($select=ID,UserID,ProjectID,IsAdmin,IsRiskAdmin;$expand=Project($select=ID,Title))`
            + `,UserPartnerOrganisations($select=ID,UserID,PartnerOrganisationID,IsAdmin,HideHeadlines,HideMilestones,HideCustomSections;$expand=PartnerOrganisation($select=ID,Title))`
            + `,DirectorateDirectorUsers($select=ID,Title)`
            + `,DirectorateReportApproverUsers($select=ID,Title)`
            + `,ProjectSeniorResponsibleOwnerUsers($select=ID,Title)`
            + `,ProjectReportApproverUsers($select=ID,Title)`
            + `,CorporateRiskRiskOwnerUsers($select=ID,Title)`
            + `,CorporateRiskReportApproverUsers($select=ID,Title)`
            + `,FinancialRiskRiskOwnerUsers($select=ID,Title)`
            + `,FinancialRiskReportApproverUsers($select=ID,Title)`
            + `,ContributorContributorUsers($expand=CorporateRisk($select=ID,Title),FinancialRisk($select=ID,Title))`
            + `,PartnerOrganisationLeadPolicySponsorUsers($select=ID,Title)`
            + `,PartnerOrganisationReportAuthorUsers($select=ID,Title)`
            + `,FinancialRiskUserGroups($select=ID,UserID,GroupID;$expand=OrgLevel1($select=ID,Title))`)).data;

        return user;
    }

    public readUsersByUsername(username: string): Promise<IUser[]> {
        return this.readAll(
            `?$select=ID,Title,Username`
            + `&$filter=toLower(Username) eq '${encodeURIComponent(username?.toLowerCase())}'`
        );
    }

    public readUsersByEmailAddress(emailAddress: string): Promise<IUser[]> {
        const customerRef: string = AppGlobals.CustomerRef;
        return this.readAll(
            `?$select=ID,Title,EmailAddress`
            + `&$filter=toLower(EmailAddress) eq '${encodeURIComponent(emailAddress?.toLowerCase())}' and Customer/CustomerRef eq '${customerRef}'`
            + `&$expand=Customer`
        );
    }

    public readActiveUsersByEmailAddress(emailAddress: string): Promise<IUser[]> {
        const customerRef: string = AppGlobals.CustomerRef;
        return this.readAll(
            `?$select=ID,Title,EmailAddress,Username`
            + `&$filter=toLower(EmailAddress) eq '${encodeURIComponent(emailAddress?.toLowerCase())}' and EntityStatusID eq 1 and Customer/CustomerRef eq '${customerRef}'`
            + `&$expand=Customer($expand=Sector)`
        );
    }

    public readForLookup(id: number): Promise<IUser> {
        return this.read(id, false, false, [], `(${id})?$select=ID,Title,Username,EmailAddress`);
    }

    public readAllForLookup(includeClosed?: boolean): Promise<IUser[]> {
        return this.readAll(
            `?$select=ID,Title,Username,EmailAddress`
            + `&$orderby=Title&$filter=CustomerID eq ${AppGlobals.CustomerID}`
            + (includeClosed ? `` : ` and EntityStatusID eq ${EntityStatus.Open}`)
        );
    }

    public readAllForList(includeDisabledUsers?: boolean): Promise<IUser[]> {
        return this.readAll(
            `?$select=ID,Title,Username,EmailAddress,EntityStatusID`
            + `&$orderby=Username&$filter=CustomerID eq ${AppGlobals.CustomerID}`
            + (includeDisabledUsers ? `` : ` and EntityStatusID eq ${EntityStatus.Open}`)
        );
    }

    public search(query: string): Promise<IUser[]> {
        return this.readAll(`?$select=ID,Title,Username&$filter=contains(Title,'${query}') or contains(Username,'${query}')&$orderby=Title`);
    }

    public async entityChildren(id: number): Promise<IEntityChildren[]> {
        const userUrl = `${this.entityUrl}(${id})`;
        const qs = `?$select=ID&$top=10`;
        const attributes: IUser[] = (await this.axiosGet(`${userUrl}/Attributes${qs}`)).data.value;
        const benefits: IUser[] = (await this.axiosGet(`${userUrl}/BenefitLeadUsers${qs}`)).data.value;
        const benefitsModified: IUser[] = (await this.axiosGet(`${userUrl}/BenefitModifiedByUsers${qs}`)).data.value;
        const benefitUpdates: IUser[] = (await this.axiosGet(`${userUrl}/BenefitUpdates${qs}`)).data.value;
        const businessPartnerGroups: IUser[] = (await this.axiosGet(`${userUrl}/GroupBusinessPartnerUsers${qs}`)).data.value;
        const commitments: IUser[] = (await this.axiosGet(`${userUrl}/CommitmentLeadUsers${qs}`)).data.value;
        const commitmentsModified: IUser[] = (await this.axiosGet(`${userUrl}/CommitmentModifiedByUsers${qs}`)).data.value;
        const commitmentUpdates: IUser[] = (await this.axiosGet(`${userUrl}/CommitmentUpdates${qs}`)).data.value;
        const contributors = (await this.axiosGet(`${userUrl}/ContributorContributorUsers?$select=BenefitID,CommitmentID,DependencyID,KeyWorkAreaID,MetricID,MilestoneID,PartnerOrganisationID,PartnerOrganisationRiskID,PartnerOrganisationRiskMitigationActionID,RiskID,RiskMitigationActionID,WorkStreamID&$top=10`)).data.value;
        const contributorsModified = (await this.axiosGet(`${userUrl}/ContributorModifiedByUsers?$select=BenefitID,CommitmentID,DependencyID,KeyWorkAreaID,MetricID,MilestoneID,PartnerOrganisationID,PartnerOrganisationRiskID,PartnerOrganisationRiskMitigationActionID,RiskID,RiskMitigationActionID,WorkStreamID&$top=10`)).data.value;
        const dependencies = (await this.axiosGet(`${userUrl}/DependencyLeadUsers${qs}`)).data.value;
        const dependenciesModified = (await this.axiosGet(`${userUrl}/DependencyModifiedByUsers${qs}`)).data.value;
        const dependencyUpdates = (await this.axiosGet(`${userUrl}/DependencyUpdates${qs}`)).data.value;
        const directorates: IUser[] = (await this.axiosGet(`${userUrl}/DirectorateDirectorUsers${qs}`)).data.value;
        const directoratesModified: IUser[] = (await this.axiosGet(`${userUrl}/DirectorateModifiedByUsers${qs}`)).data.value;
        const directoratesReportApprover: IUser[] = (await this.axiosGet(`${userUrl}/DirectorateReportApproverUsers${qs}`)).data.value;
        const directoratesReportingLead: IUser[] = (await this.axiosGet(`${userUrl}/DirectorateReportingLeadUsers${qs}`)).data.value;
        const directorateUpdates: IUser[] = (await this.axiosGet(`${userUrl}/DirectorateUpdates${qs}`)).data.value;
        const groups: IUser[] = (await this.axiosGet(`${userUrl}/GroupDirectorGeneralUsers${qs}`)).data.value;
        const groupsModified: IUser[] = (await this.axiosGet(`${userUrl}/GroupModifiedByUsers${qs}`)).data.value;
        const keyWorkAreas: IUser[] = (await this.axiosGet(`${userUrl}/KeyWorkAreaLeadUsers${qs}`)).data.value;
        const keyWorkAreasModified: IUser[] = (await this.axiosGet(`${userUrl}/KeyWorkAreaModifiedByUsers${qs}`)).data.value;
        const keyWorkAreaUpdates: IUser[] = (await this.axiosGet(`${userUrl}/KeyWorkAreaUpdates${qs}`)).data.value;
        const metrics: IUser[] = (await this.axiosGet(`${userUrl}/MetricLeadUsers${qs}`)).data.value;
        const metricsModified: IUser[] = (await this.axiosGet(`${userUrl}/MetricModifiedByUsers${qs}`)).data.value;
        const metricUpdates: IUser[] = (await this.axiosGet(`${userUrl}/MetricUpdates${qs}`)).data.value;
        const milestones: IUser[] = (await this.axiosGet(`${userUrl}/MilestoneLeadUsers${qs}`)).data.value;
        const milestonesModified: IUser[] = (await this.axiosGet(`${userUrl}/MilestoneModifiedByUsers${qs}`)).data.value;
        const milestoneUpdates: IUser[] = (await this.axiosGet(`${userUrl}/MilestoneUpdates${qs}`)).data.value;
        const modifiedByUser: IUser[] = (await this.axiosGet(`${userUrl}/UsersModifiedByUser${qs}`)).data.value;
        const partnerOrganisationLeadPolicySponsor: IUser[] = (await this.axiosGet(`${userUrl}/PartnerOrganisationLeadPolicySponsorUsers${qs}`)).data.value;
        const partnerOrganisationRiskMitigationActions: IUser[] = (await this.axiosGet(`${userUrl}/PartnerOrganisationRiskMitigationActionModifiedByUsers${qs}`)).data.value;
        const partnerOrganisationRiskMitigationActionsOwner: IUser[] = (await this.axiosGet(`${userUrl}/PartnerOrganisationRiskMitigationActionOwnerUsers${qs}`)).data.value;
        const partnerOrganisationRiskMitigationActionUpdates: IUser[] = (await this.axiosGet(`${userUrl}/PartnerOrganisationRiskMitigationActionUpdates${qs}`)).data.value;
        const partnerOrganisationRiskRiskTypes: IUser[] = (await this.axiosGet(`${userUrl}/PartnerOrganisationRiskRiskTypes${qs}`)).data.value;
        const partnerOrganisationRisksBeisRiskOwner: IUser[] = (await this.axiosGet(`${userUrl}/PartnerOrganisationRiskBeisRiskOwnerUsers${qs}`)).data.value;
        const partnerOrganisationRisksModified: IUser[] = (await this.axiosGet(`${userUrl}/PartnerOrganisationRiskModifiedByUsers${qs}`)).data.value;
        const partnerOrganisationRisksRiskOwner: IUser[] = (await this.axiosGet(`${userUrl}/PartnerOrganisationRiskRiskOwnerUsers${qs}`)).data.value;
        const partnerOrganisationRiskUpdates: IUser[] = (await this.axiosGet(`${userUrl}/PartnerOrganisationRiskUpdates${qs}`)).data.value;
        const partnerOrganisationsModified: IUser[] = (await this.axiosGet(`${userUrl}/PartnerOrganisationModifiedByUsers${qs}`)).data.value;
        const partnerOrganisationsReportAuthor: IUser[] = (await this.axiosGet(`${userUrl}/PartnerOrganisationReportAuthorUsers${qs}`)).data.value;
        const partnerOrganisationUpdates: IUser[] = (await this.axiosGet(`${userUrl}/PartnerOrganisationUpdates${qs}`)).data.value;
        const projectManagerProjects: IUser[] = (await this.axiosGet(`${userUrl}/ProjectProjectManagerUsers${qs}`)).data.value;
        const projectsModified: IUser[] = (await this.axiosGet(`${userUrl}/ProjectModifiedByUsers${qs}`)).data.value;
        const projectsReportApprover: IUser[] = (await this.axiosGet(`${userUrl}/ProjectReportApproverUsers${qs}`)).data.value;
        const projectsReportingLead: IUser[] = (await this.axiosGet(`${userUrl}/ProjectReportingLeadUsers${qs}`)).data.value;
        const projectUpdates: IUser[] = (await this.axiosGet(`${userUrl}/ProjectUpdates${qs}`)).data.value;
        const riskChampionGroups: IUser[] = (await this.axiosGet(`${userUrl}/GroupRiskChampionDeputyDirectorUsers${qs}`)).data.value;
        const riskMitigationActions: IUser[] = (await this.axiosGet(`${userUrl}/RiskMitigationActionOwnerUsers${qs}`)).data.value;
        const riskMitigationActionsModified: IUser[] = (await this.axiosGet(`${userUrl}/RiskMitigationActionModifiedByUsers${qs}`)).data.value;
        const risks: IUser[] = (await this.axiosGet(`${userUrl}/CorporateRiskRiskOwnerUsers${qs}`)).data.value;
        const risksModifiedByUsers: IUser[] = (await this.axiosGet(`${userUrl}/CorporateRiskModifiedByUsers${qs}`)).data.value;
        const risksReportApprovers: IUser[] = (await this.axiosGet(`${userUrl}/CorporateRiskReportApproverUsers${qs}`)).data.value;
        const riskUpdates: IUser[] = (await this.axiosGet(`${userUrl}/CorporateRiskUpdates${qs}`)).data.value;
        const seniorResponsibleOwnerProjects: IUser[] = (await this.axiosGet(`${userUrl}/ProjectSeniorResponsibleOwnerUsers${qs}`)).data.value;
        const signOffs: IUser[] = (await this.axiosGet(`${userUrl}/SignOffs${qs}`)).data.value;
        const userDirectorates: IUser[] = (await this.axiosGet(`${userUrl}/UserDirectorates${qs}`)).data.value;
        const userDirectoratesModified: IUser[] = (await this.axiosGet(`${userUrl}/UserDirectorateModifiedByUsers${qs}`)).data.value;
        const userGroups: IUser[] = (await this.axiosGet(`${userUrl}/UserGroups${qs}`)).data.value;
        const userGroupsModified: IUser[] = (await this.axiosGet(`${userUrl}/UserGroupModifiedByUsers${qs}`)).data.value;
        const userPartnerOrganisations: IUser[] = (await this.axiosGet(`${userUrl}/UserPartnerOrganisations${qs}`)).data.value;
        const userPartnerOrganisationsModified: IUser[] = (await this.axiosGet(`${userUrl}/UserPartnerOrganisationModifiedByUsers${qs}`)).data.value;
        const userProjects: IUser[] = (await this.axiosGet(`${userUrl}/UserProjects${qs}`)).data.value;
        const userProjectsModified: IUser[] = (await this.axiosGet(`${userUrl}/UserProjectModifiedByUsers${qs}`)).data.value;
        //const userRiskMitigationActionUpdates: IUser[] = (await this.axiosGet(`${userUrl}/CorporateRiskMitigationActionUpdates${qs}`)).data.value;
        const userRoles: IUser[] = (await this.axiosGet(`${userUrl}/UserRoles${qs}`)).data.value;
        const userRolesModified: IUser[] = (await this.axiosGet(`${userUrl}/UserRoleModifiedByUsers${qs}`)).data.value;
        const workStreams: IUser[] = (await this.axiosGet(`${userUrl}/WorkStreamLeadUsers${qs}`)).data.value;
        const workStreamsModified: IUser[] = (await this.axiosGet(`${userUrl}/WorkStreamModifiedByUsers${qs}`)).data.value;
        const workStreamUpdates: IUser[] = (await this.axiosGet(`${userUrl}/WorkStreamUpdates${qs}`)).data.value;

        return [
            { ChildType: 'Attributes', CanBeAdopted: true, ChildIDs: (await attributes).map(a => a.ID) },
            { ChildType: 'Benefits', CanBeAdopted: true, ChildIDs: (await benefits).map(b => b.ID) },
            { ChildType: 'Benefits', CanBeAdopted: true, ChildIDs: (await benefitsModified).map(b => b.ID) },
            { ChildType: 'Benefit updates', CanBeAdopted: false, ChildIDs: (await benefitUpdates).map(b => b.ID) },
            { ChildType: 'Groups', CanBeAdopted: true, ChildIDs: (await businessPartnerGroups).map(b => b.ID) },
            { ChildType: 'Commitments', CanBeAdopted: true, ChildIDs: (await commitments).map(c => c.ID) },
            { ChildType: 'CommitmentsModified', CanBeAdopted: true, ChildIDs: (await commitmentsModified).map(c => c.ID) },
            { ChildType: 'CommitmentUpdates', CanBeAdopted: true, ChildIDs: (await commitmentUpdates).map(c => c.ID) },
            { ChildType: 'Contributor benefits', CanBeAdopted: true, ChildIDs: (await contributors).filter(c => c['BenefitID']).map(c => c['BenefitID']) },
            { ChildType: 'Contributor commitments', CanBeAdopted: true, ChildIDs: (await contributors).filter(c => c['CommitmentID']).map(c => c['CommitmentID']) },
            { ChildType: 'Contributor dependencies', CanBeAdopted: true, ChildIDs: (await contributors).filter(c => c['DependencyID']).map(c => c['DependencyID']) },
            { ChildType: 'Contributor key work areas', CanBeAdopted: true, ChildIDs: (await contributors).filter(c => c['KeyWorkAreaID']).map(c => c['KeyWorkAreaID']) },
            { ChildType: 'Contributor metrics', CanBeAdopted: true, ChildIDs: (await contributors).filter(c => c['MetricID']).map(c => c['MetricID']) },
            { ChildType: 'Contributor milestones', CanBeAdopted: true, ChildIDs: (await contributors).filter(c => c['MilestoneID']).map(c => c['MilestoneID']) },
            { ChildType: 'Contributor partner organisations', CanBeAdopted: true, ChildIDs: (await contributors).filter(c => c['PartnerOrganisationID']).map(c => c['PartnerOrganisationID']) },
            { ChildType: 'Contributor partner organisation risks', CanBeAdopted: true, ChildIDs: (await contributors).filter(c => c['PartnerOrganisationRiskID']).map(c => c['PartnerOrganisationRiskID']) },
            { ChildType: 'Contributor partner organisation risk mitigation actions', CanBeAdopted: true, ChildIDs: (await contributors).filter(c => c['PartnerOrganisationRiskMitigationActionID']).map(c => c['PartnerOrganisationRiskMitigationActionID']) },
            { ChildType: 'Contributor risks', CanBeAdopted: true, ChildIDs: (await contributors).filter(c => c['RiskID']).map(c => c['RiskID']) },
            { ChildType: 'Contributor risk mitigating actions', CanBeAdopted: true, ChildIDs: (await contributors).filter(c => c['RiskMitigationActionID']).map(c => c['RiskMitigationActionID']) },
            { ChildType: 'Contributor work streams', CanBeAdopted: true, ChildIDs: (await contributors).filter(c => c['WorkStreamID']).map(c => c['WorkStreamID']) },
            { ChildType: 'Contributors modified', CanBeAdopted: false, ChildIDs: (await contributorsModified).filter(c => c['BenefitID']).map(c => c['BenefitID']) },
            { ChildType: 'Contributors modified', CanBeAdopted: false, ChildIDs: (await contributorsModified).filter(c => c['CommitmentID']).map(c => c['CommitmentID']) },
            { ChildType: 'Contributors modified', CanBeAdopted: false, ChildIDs: (await contributorsModified).filter(c => c['DependencyID']).map(c => c['DependencyID']) },
            { ChildType: 'Contributors modified', CanBeAdopted: false, ChildIDs: (await contributorsModified).filter(c => c['KeyWorkAreaID']).map(c => c['KeyWorkAreaID']) },
            { ChildType: 'Contributors modified', CanBeAdopted: false, ChildIDs: (await contributorsModified).filter(c => c['MetricID']).map(c => c['MetricID']) },
            { ChildType: 'Contributors modified', CanBeAdopted: false, ChildIDs: (await contributorsModified).filter(c => c['MilestoneID']).map(c => c['MilestoneID']) },
            { ChildType: 'Contributors modified', CanBeAdopted: false, ChildIDs: (await contributorsModified).filter(c => c['PartnerOrganisationID']).map(c => c['PartnerOrganisationID']) },
            { ChildType: 'Contributors modified', CanBeAdopted: false, ChildIDs: (await contributorsModified).filter(c => c['PartnerOrganisationRiskID']).map(c => c['PartnerOrganisationRiskID']) },
            { ChildType: 'Contributors modified', CanBeAdopted: false, ChildIDs: (await contributorsModified).filter(c => c['PartnerOrganisationRiskMitigationActionID']).map(c => c['PartnerOrganisationRiskMitigationActionID']) },
            { ChildType: 'Contributors modified', CanBeAdopted: false, ChildIDs: (await contributorsModified).filter(c => c['RiskID']).map(c => c['RiskID']) },
            { ChildType: 'Contributors modified', CanBeAdopted: false, ChildIDs: (await contributorsModified).filter(c => c['RiskMitigationActionID']).map(c => c['RiskMitigationActionID']) },
            { ChildType: 'Contributors modified', CanBeAdopted: false, ChildIDs: (await contributorsModified).filter(c => c['WorkStreamID']).map(c => c['WorkStreamID']) },
            { ChildType: 'Dependencies', CanBeAdopted: true, ChildIDs: (await dependencies).map(c => c.ID) },
            { ChildType: 'Dependencies modified', CanBeAdopted: false, ChildIDs: (await dependenciesModified).map(c => c.ID) },
            { ChildType: 'Dependency updates', CanBeAdopted: false, ChildIDs: (await dependencyUpdates).map(c => c.ID) },
            { ChildType: 'Directorates', CanBeAdopted: true, ChildIDs: (await directorates).map(c => c.ID) },
            { ChildType: 'Directorates modified', CanBeAdopted: false, ChildIDs: (await directoratesModified).map(c => c.ID) },
            { ChildType: 'Directorates', CanBeAdopted: true, ChildIDs: (await directoratesReportApprover).map(c => c.ID) },
            { ChildType: 'Directorates', CanBeAdopted: true, ChildIDs: (await directoratesReportingLead).map(c => c.ID) },
            { ChildType: 'Directorate updates', CanBeAdopted: false, ChildIDs: (await directorateUpdates).map(c => c.ID) },
            { ChildType: 'Groups', CanBeAdopted: true, ChildIDs: (await groups).map(c => c.ID) },
            { ChildType: 'Groups modified', CanBeAdopted: false, ChildIDs: (await groupsModified).map(c => c.ID) },
            { ChildType: 'Key work areas', CanBeAdopted: true, ChildIDs: (await keyWorkAreas).map(c => c.ID) },
            { ChildType: 'Key work areas modified', CanBeAdopted: false, ChildIDs: (await keyWorkAreasModified).map(c => c.ID) },
            { ChildType: 'Key work area updates', CanBeAdopted: false, ChildIDs: (await keyWorkAreaUpdates).map(c => c.ID) },
            { ChildType: 'Metrics', CanBeAdopted: true, ChildIDs: (await metrics).map(c => c.ID) },
            { ChildType: 'Metrics modified', CanBeAdopted: false, ChildIDs: (await metricsModified).map(c => c.ID) },
            { ChildType: 'Metric updates', CanBeAdopted: false, ChildIDs: (await metricUpdates).map(c => c.ID) },
            { ChildType: 'Milestones', CanBeAdopted: true, ChildIDs: (await milestones).map(c => c.ID) },
            { ChildType: 'Milestones modified', CanBeAdopted: false, ChildIDs: (await milestonesModified).map(c => c.ID) },
            { ChildType: 'Milestone updates', CanBeAdopted: false, ChildIDs: (await milestoneUpdates).map(c => c.ID) },
            { ChildType: 'Modified users', CanBeAdopted: false, ChildIDs: (await modifiedByUser).map(c => c.ID) },
            { ChildType: 'Partner organisations', CanBeAdopted: true, ChildIDs: (await partnerOrganisationLeadPolicySponsor).map(c => c.ID) },
            { ChildType: 'Partner organisation risk mitigation actions', CanBeAdopted: true, ChildIDs: (await partnerOrganisationRiskMitigationActions).map(c => c.ID) },
            { ChildType: 'Partner organisation risk mitigation actions', CanBeAdopted: true, ChildIDs: (await partnerOrganisationRiskMitigationActionsOwner).map(c => c.ID) },
            { ChildType: 'Partner organisation risk mitigation action updates', CanBeAdopted: false, ChildIDs: (await partnerOrganisationRiskMitigationActionUpdates).map(c => c.ID) },
            { ChildType: 'Partner organisation risks', CanBeAdopted: true, ChildIDs: (await partnerOrganisationRiskRiskTypes).map(c => c.ID) },
            { ChildType: 'Partner organisation risks', CanBeAdopted: true, ChildIDs: (await partnerOrganisationRisksBeisRiskOwner).map(c => c.ID) },
            { ChildType: 'Partner organisation risks modified', CanBeAdopted: false, ChildIDs: (await partnerOrganisationRisksModified).map(c => c.ID) },
            { ChildType: 'Partner organisation risks', CanBeAdopted: true, ChildIDs: (await partnerOrganisationRisksRiskOwner).map(c => c.ID) },
            { ChildType: 'Partner organisation risk updates', CanBeAdopted: false, ChildIDs: (await partnerOrganisationRiskUpdates).map(c => c.ID) },
            { ChildType: 'Partner organisations modified', CanBeAdopted: false, ChildIDs: (await partnerOrganisationsModified).map(c => c.ID) },
            { ChildType: 'Partner organisations', CanBeAdopted: true, ChildIDs: (await partnerOrganisationsReportAuthor).map(c => c.ID) },
            { ChildType: 'Partner organisation updates', CanBeAdopted: false, ChildIDs: (await partnerOrganisationUpdates).map(c => c.ID) },
            { ChildType: 'Projects', CanBeAdopted: true, ChildIDs: (await projectManagerProjects).map(c => c.ID) },
            { ChildType: 'Projects modified', CanBeAdopted: false, ChildIDs: (await projectsModified).map(c => c.ID) },
            { ChildType: 'Projects', CanBeAdopted: true, ChildIDs: (await projectsReportApprover).map(c => c.ID) },
            { ChildType: 'Projects', CanBeAdopted: true, ChildIDs: (await projectsReportingLead).map(c => c.ID) },
            { ChildType: 'Project updates', CanBeAdopted: false, ChildIDs: (await projectUpdates).map(c => c.ID) },
            { ChildType: 'Groups', CanBeAdopted: true, ChildIDs: (await riskChampionGroups).map(c => c.ID) },
            { ChildType: 'Risk mitigation actions', CanBeAdopted: true, ChildIDs: (await riskMitigationActions).map(c => c.ID) },
            { ChildType: 'Risk mitigation actions modified', CanBeAdopted: false, ChildIDs: (await riskMitigationActionsModified).map(c => c.ID) },
            { ChildType: 'Risks', CanBeAdopted: true, ChildIDs: (await risks).map(c => c.ID) },
            { ChildType: 'Risks modified by users', CanBeAdopted: false, ChildIDs: (await risksModifiedByUsers).map(c => c.ID) },
            { ChildType: 'Risks report approvers', CanBeAdopted: true, ChildIDs: (await risksReportApprovers).map(c => c.ID) },
            { ChildType: 'Risk updates', CanBeAdopted: false, ChildIDs: (await riskUpdates).map(c => c.ID) },
            { ChildType: 'Senior responsible owner projects', CanBeAdopted: true, ChildIDs: (await seniorResponsibleOwnerProjects).map(c => c.ID) },
            { ChildType: 'Reports', CanBeAdopted: false, ChildIDs: (await signOffs).map(c => c.ID) },
            { ChildType: 'User directorates', CanBeAdopted: true, ChildIDs: (await userDirectorates).map(c => c.ID) },
            { ChildType: 'User directorates modified', CanBeAdopted: false, ChildIDs: (await userDirectoratesModified).map(c => c.ID) },
            { ChildType: 'User groups', CanBeAdopted: true, ChildIDs: (await userGroups).map(c => c.ID) },
            { ChildType: 'User groups modified', CanBeAdopted: false, ChildIDs: (await userGroupsModified).map(c => c.ID) },
            { ChildType: 'User partner organisations', CanBeAdopted: true, ChildIDs: (await userPartnerOrganisations).map(c => c.ID) },
            { ChildType: 'User partner organisations modified', CanBeAdopted: false, ChildIDs: (await userPartnerOrganisationsModified).map(c => c.ID) },
            { ChildType: 'User projects', CanBeAdopted: true, ChildIDs: (await userProjects).map(c => c.ID) },
            { ChildType: 'User projects modified', CanBeAdopted: false, ChildIDs: (await userProjectsModified).map(c => c.ID) },
            // { ChildType: 'User risk mitigation action updates', CanBeAdopted: false, ChildIDs: (await userRiskMitigationActionUpdates).map(c => c.ID) },
            { ChildType: 'User roles', CanBeAdopted: true, ChildIDs: (await userRoles).map(c => c.ID) },
            { ChildType: 'User roles modified', CanBeAdopted: false, ChildIDs: (await userRolesModified).map(c => c.ID) },
            { ChildType: 'Work streams', CanBeAdopted: true, ChildIDs: (await workStreams).map(c => c.ID) },
            { ChildType: 'Work streams modified', CanBeAdopted: false, ChildIDs: (await workStreamsModified).map(c => c.ID) },
            { ChildType: 'Work stream updates', CanBeAdopted: false, ChildIDs: (await workStreamUpdates).map(c => c.ID) }
        ];
    }

    // public async create(user: IUser): Promise<IUser> {
    //     user.CustomerID = AppGlobals.CustomerID;
    //     return super.create(user);
    // }
}