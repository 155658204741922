import { AppGlobals } from '../AppGlobals';
import axios, { AxiosProgressEvent } from 'axios';

export class ZFileService {
    constructor() { }

    public async uploadFile(module: string, file: File, onUploadProgress: (progressEvent: AxiosProgressEvent) => void): Promise<any> {
        const formData = new FormData();
        formData.append('file', file);
        formData.append('module', module);

        try {
            const zedxApiURL = process.env.REACT_APP_ZedX_Non_Odata_API;
            const uploadURL: string = `${zedxApiURL}/zfiles/upload`;

            const accessToken = await AppGlobals.acquireAccessToken();

            const response = await axios.post(uploadURL, formData, {
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    UserId: AppGlobals.UserID,
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress: (progressEvent: AxiosProgressEvent) => {
                    onUploadProgress(progressEvent);
                }
            });
            console.log('File uploaded successfully', response.data);
            return response.data;
        } catch (error) {
            console.error('Error uploading file', error);
            throw error; // Rethrow the error to be handled by the caller
        }
    }

    public async downloadFile(fileUniqueID: string, fileName: string): Promise<void> {
        try {
            const zedxApiURL = process.env.REACT_APP_ZedX_Non_Odata_API;
            const downloadURL: string = `${zedxApiURL}/zfiles/${fileUniqueID}`;

            const accessToken = await AppGlobals.acquireAccessToken();

            const response = await axios.get(downloadURL, {
                responseType: 'blob', // Important for handling binary data
                headers: {
                    Authorization: `Bearer ${accessToken}`,
                    UserId: AppGlobals.UserID
                }
            });

            // Create a blob URL from the response data
            const fileBlob = new Blob([response.data]);
            const fileUrl = window.URL.createObjectURL(fileBlob);

            // Create a link element, set the URL, and trigger a download
            const link = document.createElement('a');
            link.href = fileUrl;
            link.setAttribute('download', fileName); // Use the dynamic file name
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);

            // Optional: revoke the object URL after download to free up memory
            window.URL.revokeObjectURL(fileUrl);

        } catch (error) {
            console.error('Error downloading file', error);
            throw error; // Rethrow the error to be handled by the caller
        }
    }
}