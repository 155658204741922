import React, { useContext, useState } from "react";
import styles from '../../styles/cr.module.scss';
import { OrbUserContext } from "../OrbUserContext";
import { CrTextField } from "../cr/CrTextField";
import { FormButtons } from "../cr/FormButtons";
import { useHistory } from "react-router-dom";
import axios from "axios";
import { FieldErrorMessage, FieldSuccessMessage } from "../cr/FieldDecorators";

interface ChangePasswordProps {
}

interface IErrorMessage {
    CurrentPassword: string;
    NewPassword: string;
    RepeatNewPassword: string;
}
export class ErrorMessage implements IErrorMessage {
    public CurrentPassword = null;
    public NewPassword = null;
    public RepeatNewPassword = null;
}

export const ChangePassword = (props: ChangePasswordProps): React.ReactElement => {

    const { userContext } = useContext(OrbUserContext);
    const history = useHistory();

    const [currentPassword, setCurrentPassword] = useState<string>('');
    const [newPassword, setNewPassword] = useState<string>('');
    const [repeatNewPassword, setRepeatNewPassword] = useState<string>('');
    const [saveErrorMsg, setSaveErorMsg] = useState<string>('');
    const [saveSuccessMsg, setSaveSuccessMsg] = useState<string>('');
    const [errMessages, setErrMessages] = useState<IErrorMessage>(new ErrorMessage());


    const validateEntity = (): boolean => {
        let returnVal: boolean = true;
        let errMsgs = { ...errMessages };

        // Check if currentPassword is empty
        if (currentPassword === '') {
            errMsgs.CurrentPassword = "Current Password is required";
            returnVal = false;
        } else {
            errMsgs.CurrentPassword = null;
        }

        // Check if newPassword is empty
        if (newPassword === '') {
            errMsgs.NewPassword = "New Password is required";
            returnVal = false;
        } else {
            errMsgs.NewPassword = null;

            // Check if newPassword is at least 6 characters long
            if (newPassword.length < 6) {
                errMsgs.NewPassword = "New Password must be at least 6 characters long";
                returnVal = false;
            } else {
                errMsgs.NewPassword = null;

                // Check if repeatNewPassword is empty
                if (repeatNewPassword === '') {
                    errMsgs.RepeatNewPassword = "Please repeat your new password";
                    returnVal = false;
                } else {
                    errMsgs.RepeatNewPassword = null;

                    // Check if newPassword matches repeatNewPassword
                    if (newPassword !== repeatNewPassword) {
                        errMsgs.RepeatNewPassword = "Passwords do not match";
                        returnVal = false;
                    } else {
                        errMsgs.RepeatNewPassword = null;
                    }
                }
            }
        }

        setErrMessages(errMsgs);

        return returnVal;
    }



    const handleChangePassword = async (): Promise<void> => {

        if (validateEntity()) {
            const zedxApiURL = process.env.REACT_APP_ZedX_Non_Odata_API!;
            const response = await axios.post(`${zedxApiURL}/Accounts/ChangePassword`, {
                UserID: userContext.UserId,
                CurrentPassword: currentPassword,
                NewPassword: newPassword,
            });
            const result: boolean = response.data;
            if (result === true) {
                setSaveErorMsg("");
                setSaveSuccessMsg("Your password has been successfully changed.");
                setCurrentPassword("");
                setNewPassword("");
                setRepeatNewPassword("");
            }
            else {
                setSaveSuccessMsg("");
                setSaveErorMsg("The current password you entered is invalid.");
            }
        }
    }

    const handleCancel = (): void => {
        history.push('/');
    }

    return (
        <div className={`${styles.cr} `}>
            <h2 style={{ flexGrow: 1 }} className={styles.listTitle}>Change Password</h2>
            <div style={{ maxWidth: '600px', marginRight: '10px', marginTop: '30px' }}>
                <CrTextField
                    label="Current Password"
                    type='password'
                    canRevealPassword
                    className={styles.formField}
                    required={true}
                    maxLength={255}
                    autoComplete="new-password"
                    value={currentPassword}
                    onChange={(ev, newValue) => setCurrentPassword(newValue)}
                    errorMessage={errMessages.CurrentPassword}
                />
                <CrTextField
                    label="New Password"
                    type='password'
                    canRevealPassword
                    className={styles.formField}
                    required={true}
                    maxLength={255}
                    autoComplete="new-password"
                    value={newPassword}
                    onChange={(ev, newValue) => setNewPassword(newValue)}
                    errorMessage={errMessages.NewPassword}
                />
                <CrTextField
                    label="Repeat New Password"
                    type='password'
                    canRevealPassword
                    className={styles.formField}
                    required={true}
                    maxLength={255}
                    autoComplete="new-password"
                    value={repeatNewPassword}
                    onChange={(ev, newValue) => setRepeatNewPassword(newValue)}
                    errorMessage={errMessages.RepeatNewPassword}
                />

                <FormButtons
                    onPrimaryClick={handleChangePassword}
                    onSecondaryClick={handleCancel}
                />

                <FieldErrorMessage value={saveErrorMsg} />
                <FieldSuccessMessage value={saveSuccessMsg} />

            </div>
        </div>
    );

};

