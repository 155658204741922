import { EntityService } from './EntityService';
import { IEntity } from '../types';

export class CustomerService extends EntityService<IEntity> {
    public readonly parentEntities = [];
    protected childrenEntities = [];

    constructor() {
        super(`/Customers`);
    }

    public readCustomerUsers(customerRef: string): Promise<IEntity[]> {
        return this.readAll(`GetCustomerUsers/${customerRef}`);
    }
}