import React, { useState, useEffect, useContext, useCallback } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { IWithErrorHandlingProps } from './withErrorHandling';
import { OrbUserContext } from './OrbUserContext';
import { ThemeProvider } from '@fluentui/react/lib/Theme';
import { darkMenuTheme } from '../AppGlobals';
import { LoggedUserInfoForMenu } from './LoggedUserInfoForMenu';
import { Nav, INavLinkGroup, INavLink } from '@fluentui/react/lib/Nav';
import { DataContext } from './DataContext';
import path from 'path';

interface IZedXMainMenu {
    onLinkClicked?: () => void;
    onLogout?: () => void;
    //authType: string;
}

export const ZedXMainMenu = ({ onLogout, onLinkClicked }: IZedXMainMenu): React.ReactElement => {

    const { pathname } = useLocation();
    const history = useHistory();
    const [navs, setNavs] = useState([]);

    // - Corporate Workflows
    // - Project Portfolios
    // - Risk Registers
    // - GIAA Actions
    // - NAO/PAC Tracker
    // - Contingent Labour
    // - Governance Statements
    // - Controls and Assurance
    // - Management Actions
    // - Agile Sprints
    // - Knowledge Hub
    // - Group Notifications

    useEffect(() => {
        const routerNav = (path: string): void => {
            history.push(path);
            if (onLinkClicked) onLinkClicked();
        };

        const navLinkGroups: INavLinkGroup[] = [
            {
                links: [
                    {
                        name: 'Home',
                        key: 'home',
                        url: '#/',
                        onClick: () => routerNav('/'),
                    },
                    {
                        name: 'Organise Servers',
                        key: '/servers',
                        url: '#/servers',
                        onClick: () => routerNav('/servers'),
                    },
                    {
                        name: 'Define Sectors',
                        key: '/sectors',
                        url: '#/sectors',
                        onClick: () => routerNav('/sectors'),
                    },
                    {
                        name: 'Apps',
                        key: '/apps',
                        url: '#/apps',
                        onClick: () => routerNav('/apps'),
                    },
                    {
                        name: 'Manage Customers',
                        key: '/customers',
                        url: '#/customers',
                    },
                    {
                        name: 'Change Password',
                        key: '/account/change-password',
                        url: '#/account/change-password',
                        onClick: () => routerNav('/account/change-password'),
                    },
                    {
                        name: 'Logout',
                        class: 'logout-link',
                        key: 'logout',
                        url: '',
                        onClick: () => {
                            onLogout();
                        }
                    },
                ].filter(Boolean), // Remove falsy values (null, undefined) from the array
            },
        ];

        setNavs(navLinkGroups);

    }, [history, onLinkClicked]);

    const selectedKey: string = pathname === "/" ? "home" : pathname;
    return (
        <ThemeProvider theme={darkMenuTheme}>
            <LoggedUserInfoForMenu />
            <Nav
                groups={navs}
                onRenderLink={(lnk) => _onRenderNavLink(lnk)}
                selectedKey={selectedKey}
            />
        </ThemeProvider>
    );
};

export function _onRenderNavLink(link: INavLink, totalPortfolios?: number) {

    const commonStyle = { paddingLeft: '10px' };
    const badgeStyle = {
        fontSize: '12px',
        backgroundColor: 'green',
        color: 'white',
        padding: '2px',
        paddingLeft: '4px',
        paddingRight: '22px',
        borderRadius: '4px'
    };

    if (link.key === '/performance-reporting/portfolios') {
        return (
            <span>
                <img width='8' height='8' src='https://placehold.co/10x10/99D9EA/99D9EA' />
                <span style={{ ...commonStyle, marginRight: '25px' }}>{link.name}</span>
                <span style={badgeStyle}>{totalPortfolios}</span>
            </span>
        );
    } else if (link.key === '/performance-reporting/programmes') {
        return (
            <span>
                <img width='8' height='8' src='https://placehold.co/10x10/99D9EA/99D9EA' />
                <span style={commonStyle}>{link.name}</span>
            </span>
        );
    } else if (link.key === '/performance-reporting/projects') {
        return (
            <span>
                <img width='8' height='8' src='https://placehold.co/10x10/99D9EA/99D9EA' />
                <span style={commonStyle}>{link.name}</span>
            </span>
        );
    }


    return <span>{link.name}</span>;
}
