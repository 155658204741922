import React, { useContext } from 'react';
import styles from '../styles/cr.module.scss';
import { IEntity, Entity, AttributeType, IAttributeType, EntityValidations, IEntityValidations, IUser, UserContext, IOrgLevel1, ListDefaults, IDirectorate, IOrgLevel3 } from '../types';
import { EntityList } from '../components/EntityList';
import { UserRoleForm } from '../components/user/UserRoleForm';
import { UserForm } from '../components/user/UserForm';
import { ThresholdForm } from '../components/threshold/ThresholdForm';
import { ThresholdAppetiteForm } from '../components/threshold/ThresholdAppetiteForm';
import { UserProjectForm } from '../components/user/UserProjectForm';
import { UserPartnerOrganisationForm } from '../components/user/UserPartnerOrganisationForm';
import { EntityForm } from '../components/EntityForm';
import { UserDirectorateForm } from '../components/user/UserDirectorateForm';
import { UserGroupForm } from '../components/user/UserGroupForm';
import { RiskTypeForm } from '../components/risk/RiskTypeForm';
import { ErrorBoundary } from '../components/ErrorBoundary';
import { CrTextField } from '../components/cr/CrTextField';
import { Checkbox } from '@fluentui/react/lib/Checkbox';
import { EntityStatus } from '../refData/EntityStatus';
import { IUseApiProps } from '../components/useApi';
import { IWithErrorHandlingProps, withErrorHandling } from '../components/withErrorHandling';
import { ReportingFrequencyForm } from './reportingFrequency/ReportingFrequencyForm';
import { FinancialRiskUserGroupForm } from './user/FinancialRiskUserGroupForm';
import { ReportingEntityTypeForm } from './reportingEntities/ReportingEntityTypeForm';
import { DataContext } from './DataContext';
import { ICommandBarItemProps } from '@fluentui/react/lib/CommandBar';
import { IntegrationContext } from './IntegrationContext';
import { EntityWithCustomer, IEntityWithCustomer } from '../types/Entity';
import { OrbUserContext } from './OrbUserContext';
import { GroupForm } from './group/GroupForm';
import { DirectorateFormAdmin } from './directorate/DirectorateFormAdmin';
import useMobileDetector from './useMobileDetector';
import { OrgLevel3Form } from './orgLevel3/OrgLevel3Form';
import { CustomerForm } from './customer/CustomerForm';
import { useHistory, useParams } from 'react-router-dom';

export interface ISystemAdministrationProps extends IUseApiProps, IWithErrorHandlingProps {
	entity: string;
}

const SystemAdministration = (props: ISystemAdministrationProps): React.ReactElement => {
	const { entity } = props;
	const { dataServices, loadLookupData } = useContext(DataContext);
	const { orbConfig } = useContext(OrbUserContext);
	const history = useHistory();
	//const { disableUserManagement, disableGroupManagement, disableDirectorateManagement } = useContext(IntegrationContext);
	const lcc = ListDefaults.columnWidths;
	const isMobile = useMobileDetector();

	const sectorNameWidths = isMobile ? { minWidth: 100, maxWidth: 120 } : { minWidth: 150, maxWidth: 300 };
	const emailWidths = isMobile ? { minWidth: 90, maxWidth: 170 } : { minWidth: 250, maxWidth: 300 };
	const userWidths = isMobile ? { minWidth: 100, maxWidth: 120 } : { minWidth: 150, maxWidth: 300 };
	const attributeNameWidths = isMobile ? { minWidth: 90, maxWidth: 170 } : { minWidth: 150, maxWidth: 300 };
	const riskTypeNameWidths = isMobile ? { minWidth: 130, maxWidth: 170 } : { minWidth: 200, maxWidth: 350 };
	const thresholdNameWidths = isMobile ? { minWidth: 150, maxWidth: 170 } : { minWidth: 200, maxWidth: 350 };
	const riskImpactLevelWidths = isMobile ? { minWidth: 100, maxWidth: 120 } : { minWidth: 150, maxWidth: 300 };

	const { customerRef } = useParams<{ customerRef: string }>();

	const getPreFarItems = (keys: string[]) => {
		const config: Record<string, ICommandBarItemProps> = {
			Customers: {
				key: 'Customers',
				name: 'Customers',
				iconProps: { iconName: 'MiniExpandMirrored' },
				onClick() {
					//props.setLastPortfolioIdForChilds(null);
					history.push('/customers');
				},
			},

			// Add more configurations as needed for other keys
		};

		const arr: ICommandBarItemProps[] = keys
			.filter((key) => config[key]) // Only include valid keys
			.map((key) => config[key]);

		return arr;
	};

	return (
		<ErrorBoundary>
			{entity === 'Sectors' &&
				<EntityList
					{...props}
					entityName={{ Plural: "Sectors", Singular: "Sector" }}
					columns={[
						{ key: '1', name: 'Name', fieldName: 'Name', ...sectorNameWidths, isResizable: true, isMultiline: true },
						{ key: '2', name: 'Apps', fieldName: 'Apps', minWidth: 200, isResizable: true, isMultiline: true },
					]}
					loadListItems={() => dataServices.sectorService.readAllForList()}
					mapEntitiesToListItems={(entities: IEntity[]) => entities.map(u => (
						{
							key: u.ID,
							Name: u.Title,
							Apps: u['Apps']
						}
					))}
					entityForm={null}
					//onChange={() => loadLookupData.measurementUnits(true)}
					onCheckDelete={id => dataServices.sectorService.entityChildren(id)}
					onDelete={id => dataServices.sectorService.delete(id)}
					disableAdd={true}
					disableDelete={(e) => { return true; }}

				/>
			}
			{entity === 'Servers' &&
				<EntityList
					{...props}
					entityName={{ Plural: "Servers", Singular: "Server" }}
					columns={[
						{ key: '1', name: 'Name', fieldName: 'Name', minWidth: 200, isResizable: true }
					]}
					loadListItems={() => dataServices.serverService.readAll()}
					mapEntitiesToListItems={(entities: IEntity[]) => entities.map(u => (
						{
							key: u.ID,
							Name: u.Title
						}
					))}
					entityForm={null}
					//onChange={() => loadLookupData.measurementUnits(true)}
					onCheckDelete={id => dataServices.serverService.entityChildren(id)}
					onDelete={id => dataServices.serverService.delete(id)}
					disableAdd={true}
					disableDelete={(e) => { return true; }}
				/>
			}
			{entity === 'Apps' &&
				<EntityList
					{...props}
					entityName={{ Plural: "Apps", Singular: "App" }}
					columns={[
						{ key: '1', name: 'Name', fieldName: 'Name', ...sectorNameWidths, isResizable: true, isMultiline: true },
						{ key: '2', name: 'Sectors', fieldName: 'Sectors', minWidth: 200, isResizable: true, isMultiline: true },
					]}
					loadListItems={() => dataServices.availableAppService.readAllForList()}
					mapEntitiesToListItems={(entities: IEntity[]) => entities.map(u => (
						{
							key: u.ID,
							Name: u.Title,
							Sectors: u['Sectors']
						}
					))}
					entityForm={null}
					//onChange={() => loadLookupData.measurementUnits(true)}
					onCheckDelete={id => dataServices.availableAppService.entityChildren(id)}
					onDelete={id => dataServices.availableAppService.delete(id)}
					disableAdd={true}
					disableDelete={(e) => { return true; }}

				/>
			}
			{entity === 'Customers' &&
				<EntityList
					{...props}
					entityName={{ Plural: "Customers", Singular: "Customers" }}
					columns={[
						{ key: '1', name: 'Name', fieldName: 'Name', minWidth: 200, isResizable: true }
					]}
					loadListItems={() => dataServices.customerService.readAll()}
					mapEntitiesToListItems={(entities: IEntity[]) => entities.map(u => (
						{
							key: u.ID,
							Name: u.Title
						}
					))}
					preFarItemsOnSelection={(c) => {
						const menuItems = [
							{
								key: 'Users',
								name: 'Users',
								iconProps: { iconName: 'MiniExpand' },
								onClick: (ev, item) => {
									//props.setLastProjectIdForChilds(p?.ID);
									history.push(`/customer-users/${c?.['CustomerRef']}`);
								},
							},
						];

						return menuItems;
					}}
					entityForm={(showForm, entityId, onSaved, onCancelled, entityDescription1) =>
						<CustomerForm
							{...props}
							showForm={showForm}
							entityId={entityId}
							onSaved={onSaved}
							onCancelled={onCancelled}
						/>
					}
					//onChange={() => loadLookupData.measurementUnits(true)}
					onCheckDelete={id => dataServices.customerService.entityChildren(id)}
					onDelete={id => dataServices.customerService.delete(id)}
					disableDelete={(e) => { return true; }}
				/>
			}
			{entity === 'CustomerUsers' &&
				<EntityList
					{...props}
					entityName={{ Plural: "Customer Users", Singular: "Customer User" }}
					columns={[
						{ key: '2', name: 'Display name', fieldName: 'DisplayName', ...userWidths, isResizable: true, isCollapsible: true },
						{ key: '3', name: 'Email address', fieldName: 'EmailAddress', ...emailWidths, isResizable: true, isCollapsible: true },
						{ key: '4', name: 'Enabled?', fieldName: 'Enabled', minWidth: 100, isResizable: true, isCollapsible: true }
					]}
					loadListItems={() => dataServices.customerService.readCustomerUsers(customerRef)}
					mapEntitiesToListItems={(entities: IEntity[]) => entities.map(u => (
						{
							key: u.ID,
							DisplayName: u.Title,
							EmailAddress: u['EmailAddress'],
							Enabled: u['EntityStatusID'] === EntityStatus.Open ? 'Yes' : 'No'
						}
					))}
					preFarItems={(u) => getPreFarItems(['Customers'])}
					entityForm={(showForm, entityId, onSaved, onCancelled) =>
						<UserForm
							{...props}
							showForm={showForm}
							entityId={entityId}
							onSaved={onSaved}
							onCancelled={onCancelled}
							customerRef={customerRef}
						/>
					}
					//onChange={() => loadLookupData.measurementUnits(true)}
					onCheckDelete={id => dataServices.customerService.entityChildren(id)}
					onDelete={id => dataServices.customerService.delete(id)}
					disableDelete={(e) => { return true; }}
				/>


			}

		</ErrorBoundary>
	);
};

export default withErrorHandling(SystemAdministration);
